<template>
  <div class="main">
    <el-switch
      style="margin-top: 10px;"
      v-model="listQuery.type"
      active-color="#13ce66"
      inactive-color=""
      active-text="更新"
      inactive-text=""
      @click="dialog"
      size="small"
    >
    </el-switch>
    <div style="margin-top: 10px;">
      <el-button type="primary" size="small" @click="addTheme" icon="Plus">新增</el-button>
    </div>
    <el-table
      :data="list"
      :height="contentStyleObj"
      style="width: 100%;margin-top: 10px;"
      border
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="公司名称" align="left" width="230" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.comName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="域名" align="center" width="170" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.domainName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="浏览器标题" align="center" width="120" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="打印落款" align="center" width="110" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.outName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="通知模板Id" align="center" width="110" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.notifyId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="主账号验证码Id" align="center" width="120" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.adminSmsId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="验证码Id" align="center" width="110" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.smsId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="阿里云识别码" align="center" width="110" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.accessKeyId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="阿里云密钥" align="center" width="110" show-overflow-tooltip>
        <template #default="scope">
          <span>{{ scope.row.accessKeySecret }}</span>
        </template>
      </el-table-column>
      <el-table-column label="logo" align="center" width="90">
        <template #default="scope">
          <el-image
            style="width: auto;height:60px"
            :src="scope.row.logo"
            :preview-src-list="[scope.row.logo]"
            z-index="9999"
            preview-teleported="true"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="登录页图片" align="center" width="90">
        <template #default="scope">
          <el-image
            style="width: auto;height:60px"
            :src="scope.row.loginImg"
            z-index="9999"
            preview-teleported="true"
            :preview-src-list="[scope.row.loginImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="浏览器图标" align="center" width="90">
        <template #default="scope">
          <el-image
            style="width: auto;height:60px"
            :src="scope.row.ico"
            z-index="9999"
            preview-teleported="true"
            :preview-src-list="[scope.row.ico]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="主题色" align="center" min-width="130">
        <template #default="scope">
          <el-color-picker
            v-model="scope.row.colour1"
            :disabled="true"
          ></el-color-picker>
          <span style="margin-left: 5px;">{{ scope.row.colour1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="主题浅色" align="center" min-width="130">
        <template #default="scope">
          <el-color-picker
            v-model="scope.row.colour2"
            :disabled="true"
          ></el-color-picker>
          <span style="margin-left: 5px;">{{ scope.row.colour2 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="主题浅色按钮" align="center" min-width="130">
        <template #default="scope">
          <el-color-picker
            v-model="scope.row.colour3"
            :disabled="true"
          ></el-color-picker>
          <span style="margin-left: 5px;">{{ scope.row.colour3 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="边框颜色" align="center" width="150">
        <template #default="scope">
          <el-color-picker
            v-model="scope.row.colour4"
            :disabled="true"
          ></el-color-picker>
          <span style="margin-left: 5px;">{{ scope.row.colour4 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="editTheme(scope.row)"
            plain
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="delTheme(scope.row)"
            plain
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    title="主题设置"
    v-model="dialogThemeVisible"
    width="80%"
    destroy-on-close
  >
    <el-form :model="temp" label-position="right" :inline="true">
      <div>
        <el-form-item label="集团名称：" :label-width="formLabelWidth">
          <el-select placeholder="请选择" v-model="temp.groupId" size="small" filterable style="width:265px">
            <el-option
              v-for="(item, index) in groupOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="域名：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.domainName"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="浏览器标题：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.title"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="打印落款：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.outName"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="通知模板Id：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.notifyId"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="主账号验证码Id：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.adminSmsId"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码Id：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.smsId"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="阿里云识别码：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.accessKeyId"
            style="width:275px"
          ></el-input>
        </el-form-item>
        <el-form-item label="阿里云密钥：" :label-width="formLabelWidth">
          <el-input
            class="input"
            size="small"
            v-model="temp.accessKeySecret"
            style="width:275px"
          ></el-input>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="logo：" :label-width="formLabelWidth">
          <upload @uploadimgzj="getLogo" style="margin-left:0" />
        </el-form-item>
        <el-form-item label="登录页图片：" :label-width="formLabelWidth">
          <upload @uploadimgzj="getLogin" style="margin-left:0" />
        </el-form-item>
        <el-form-item label="浏览器图标：" :label-width="formLabelWidth">
          <upload @uploadimgzj="getIco" style="margin-left:0" />
        </el-form-item>
      </div>
      <div>
        <el-form-item label="展开logo：" :label-width="formLabelWidth">
          <upload @uploadimgzj="getBigLogo" style="margin-left:0" />
        </el-form-item>
        <el-form-item label="折叠logo：" :label-width="formLabelWidth">
          <upload @uploadimgzj="getSmallLogo" style="margin-left:0" />
        </el-form-item>
        <el-form-item label="菜单背景图：" :label-width="formLabelWidth">
          <upload @uploadimgzj="getMenuBg" style="margin-left:0" />
        </el-form-item>
      </div>
      <div>
        <el-form-item label="主题色：" :label-width="formLabelWidth">
          <el-color-picker v-model="temp.colour1"></el-color-picker>
        </el-form-item>
        <el-form-item label="主题浅色:" :label-width="formLabelWidth">
          <el-color-picker v-model="temp.colour2"></el-color-picker>
        </el-form-item>
        <el-form-item
          label="主题浅色按钮:"
          :label-width="formLabelWidth"
        >
          <el-color-picker v-model="temp.colour3"></el-color-picker>
        </el-form-item>
        <el-form-item label="边框颜色：" :label-width="formLabelWidth">
          <el-color-picker v-model="temp.colour4"></el-color-picker>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogThemeVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="saveTheme"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    :close-on-click-modal="false"
    title="提示"
    v-model="dialogVisible"
    width="30%"
    :before-close="UpdateCell"
    destroy-on-close
  >
    <span>
      <!-- <vWangeditor @change="changeContent" ref="text" :content="listQuery.content" class="myQuillEditor" :options="editorOption"></vWangeditor> -->
      <el-input
        class="GXupdate"
        type="textarea"
        v-model="listQuery.content"
        style="height: 150px;"
      ></el-input>
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="UpdateCell" size="small">取 消</el-button>
        <el-button type="primary" @click="UpdateInfo" size="small"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElColorPicker } from "element-plus";
import { systemUpdate, systemUpdateInfo, groupList } from "@/api/org.js";
import { saveComColour, comColourList, deleteComColour } from "@/api/company";
import upload from "@/components/uploadImg/upload";
export default {
  components: {
    upload,
  },
  data() {
    return {
      listQuery: {
        type: false,
        content: "",
      },
      dialogVisible: false,
      editorOption: {},
      temp: {
        comName: "",
        domainName: "",
        logo: "",
        loginImg: "",
        ico: "",
        colour1: "",
        colour2: "",
        colour3: "",
        colour4: "",
        title:"",
        outName:"",
        notifyId:"",
        smsId:"",
        accessKeyId:"",
        accessKeySecret:"",
        adminSmsId:"",
        unfoldLogo:"",
        foldLogo:"",
        menuBg:""
      },
      showColorPicker: true,
      formLabelWidth: "170px",
      list: [],
      contentStyleObj: {},
      dialogThemeVisible: false,
      groupOptions: [],
    };
  },
  created() {
    this.getList();
    this.contentStyleObj = this.$getHeight(280);
    this.getThemeList();
    this.init();
  },
  methods: {
    //查询集团
    init() {
      groupList({}).then((res) => {
        if (res.data.msg == "success") {
          this.groupOptions = res.data.data.list;
        }
      });
    },
    dialog() {
      // if(this.listQuery.value == true) {
      this.dialogVisible = true;
      // }
    },
    changeContent(e) {
      this.listQuery.content = e.html;
    },
    UpdateCell() {
      this.getList();
      this.dialogVisible = false;
    },
    UpdateInfo() {
      systemUpdate(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.$message({
            message: "更新成功",
            type: "success",
          });
          this.getList();
          this.dialogVisible = false;
        }
      });
    },
    getList() {
      systemUpdateInfo().then((res) => {
        //console.log(res.data.data, "res");
        this.listQuery.type = res.data.data;
      });
    },
    //新增主题色
    getThemeList() {
      comColourList({}).then((res) => {
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
        }
      });
    },
    addTheme() {
      this.dialogThemeVisible = true;
      this.temp = {
        comName: "",
        domainName: "",
        logo: "",
        loginImg: "",
        ico: "",
        colour1: "",
        colour2: "",
        colour3: "",
        colour4: "",
        title:"",
        outName:"",
        notifyId:"",
        smsId:"",
        accessKeyId:"",
        accessKeySecret:"",
        adminSmsId:"",
        unfoldLogo:"",
        foldLogo:"",
        menuBg:""
      };
    },
    getLogo(val) {
      this.temp.logo = val;
    },
    getLogin(val) {
      this.temp.loginImg = val;
    },
    getIco(val) {
      this.temp.ico = val;
    },
    getBigLogo(val){
      this.temp.unfoldLogo = val;
    },
    getSmallLogo(val){
      this.temp.foldLogo = val;
    },
    getMenuBg(val){
      this.temp.menuBg = val;
    },
    //编辑
    editTheme(row) {
      this.temp = Object.assign({}, row);
      this.dialogThemeVisible = true;
    },
    saveTheme() {
      saveComColour(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogThemeVisible = false;
          this.getThemeList();
        }
      });
    },
    //删除
    delTheme(row) {
      this.$confirm("你确定要删除该主题吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteComColour({ id: row.id }).then((res) => {
          if (res.data.msg == "success") {
            this.getThemeList();
            this.$qzfMessage("删除成功");
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.GXupdate .el-textarea__inner {
  height: 150px !important;
}
.main {
  width: 98%;
  margin: 0 auto;
}
</style>
